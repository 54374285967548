import React from 'react'
import { graphql } from 'gatsby';

import Layout from '../App/components/layout';
import TypeList from '../App/components/Typelist';

import SEO from "../App/components/seo"

const manufatureIconsList = ({data}) => (
    <Layout>
      <SEO title="" />
       <TypeList typeObject={data.craft.categories}/>
    </Layout>
    
);

export default manufatureIconsList;

export const data = graphql`
{
    craft{
        categories(group: elevatorType, orderBy:"title asc"){
            ...on Craft_ElevatorTypeCategory{
              title
              asset{
                id
                url
              }
            } 
        }
    }
}`;